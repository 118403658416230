<template>
  <div class="msk">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  components: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped >
.loader {
  text-indent: -9999em;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 13px rgba(0, 0, 0, 0.4);
  -webkit-animation: load-effect 2s infinite linear;
  animation: load-effect 1.2s infinite linear;
}
/*绘制纯白1/4圆*/

.loader::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 50%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 100% 0 0 0;
  left: 0;
  top: 0;
}

@keyframes load-effect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
