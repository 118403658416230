<template @scroll.native="goTop">
  <div>
    <div class="txt">
      <div class="top">
        <img
          class="close"
          @click="close"
          src="../assets/images/close.png"
          alt=""
        />
      </div>
      <div class="agreement">
        <h1>隐私政策</h1>
        <br />
        <br />
        <p>
          本公司作为TutorData网站的运营者，非常注重用户个人信息的保护和隐私权保护。我们将按本隐私政策收集、使用、共享和保护您以及您产品终端用户的个人信息。请仔细完整的阅读以下隐私政策内容以了解我们是如何收集和使用来自于或关于您和您的终端用户的信息的。
        </p>
        <p>
          请注意，访问TutorData网站或使用我们的服务，表示您已阅读、知悉和同意接受本隐私政策。本政策在您使用网站和服务的整个过程中有效，如果您不同意本政策，您应立即停止使用TutorData网站以及如下所具体介绍的服务。
        </p>
        <br />
        <h2>一、适用范围</h2>
        <br />
        <p>
          本隐私政策适用于TutorData提供的各项服务，您访问TutorData平台、使用TutorData提供的服务包括但不限于我们提供的网站、SDK、API、组件、代码、工具以及不断创新研发的产品和服务，均适用于本隐私政策。
        </p>
        <p>
          需要说明的是，本隐私政策不适用于展示在、链接到或再封装我们服务的那些适用于其他隐私权政策、由第三方提供的服务。
        </p>
        <p>
          如果您允许我们与第三方网站或服务共享的信息，则同时需受第三方网站服务条款和隐私政策的约束。
        </p>
        <br />
        <h2>二、我们如何收集和使用信息</h2>
        <br />
        <p>
          我们收集信息是为了向您提供更优质、更个性化的服务，我们收集信息的方式如下：
        </p>
        <h3>1、在您访问和浏览TutorData网站时收集信息</h3>
        <p>当您访问我们网站时，和大多数网站一样，我们会：</p>
        <br />
        <ul>
          <li>使用cookie技术在您电脑存储您的会话/选择信息；</li>
          <li>使用Web Beacons等软件技术来告知我们哪些内容有效；</li>
          <li>
            自动收集某些信息并存储在日志文件和数据仓库中。这些信息包括：Internet协议(IP)地址、浏览器类型、Internet服务提供商（ISP）、操作系统等软硬件特征，日期/时间戳，页面访问及使用行为等。
          </li>
        </ul>
        <br />
        <h3>2、在您使用TutorData服务时收集信息</h3>
        <p>
          2.1
          当您注册TutorData服务的时候，我们会要求您提供您的个人信息，包括手机号码、电子邮件地址、姓名、密码等来创建账户。当您后续与我们联系并持续使用TutorData服务时，我们可能会要求您提供更多的联系信息，包括：
        </p>
        <br />
        <ul>
          <li>联系人通讯地址、电话号码、职务和职位；</li>
          <li>您的企业信息，例如企业名称和业务类型；</li>
          <li>
            您的产品信息，例如移动应用的名称、内容、类别和某些特性，推广渠道信息等。
          </li>
        </ul>
        <br />
        <p>我们使用这些信息是用于：</p>
        <br />
        <ul>
          <li>向您发送注册确认；</li>
          <li>了解和管理账户；</li>
          <li>回应您的客户服务要求；</li>
          <li>了解您的企业和产品需求以确定适用的产品和服务；</li>
          <li>
            基于市场营销和促销的目的联系您，并为您提供更多有关我们产品的信息；
          </li>
          <li>进行研究和分析，提升我们的网站和服务质量。</li>
        </ul>
        <br />
        <p>
          2.2
          当您在产品中集成了TutorData的SDK或数据上传API后，您的产品就会通过集成的SDK和API传送数据到我们的服务器。
        </p>
        <p>
          可以通过TutorData
          SDK及API收集的数据包括但不限于：您产品终端用户的SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。我们可能对MAC地址、IMEI、IDFA等可以用于识别终端设备的信息进行加密，从而提高对隐私的保护。
        </p>
        <p>
          如果您在集成TutorData工具时使用了事件（track）和用户标识（identify），我们还收集并存储以下数据：您产品终端用户的标识符、性别、年龄等描述终端用户身份及特征的数据，以及用户触发的事件和页面访问数据。
        </p>
        <p>
          我们使用这些数据是用于：向您提供您的产品及终端用户数据的统计分析服务，帮助您从整体及个体上了解您的终端用户。您可以通过您在我们网站上的账号登录我们的网站或App来查阅相关统计和分析结果。
        </p>
        <p>
          2.3
          我们在网站上留下电子邮箱、在线客服允许您主动联系我们。当您通过电子邮箱与我们联系时您可能提供个人信息，我们将使用这些信息回应您的问题和服务要求，改善我们的网站和服务。
        </p>
        <br />
        <h2>三、您对终端用户数据的使用</h2>
        <br />
        <p>
          我们不控制我们的客户如何使用属于或关于他们的最终用户的数据，也不应为此负责，尽管我们期待我们的客户以符合他们的法律和道德义务的方式使用他们最终用户的数据。最终用户应该审查我们的客户的隐私权政策，从而了解他们如何收集、使用最终用户的数据。
        </p>
        <p>
          如果您接受本隐私权政策并把我们的SDK、API、JS代码或其他统计工具集成到您的产品中，您已经同意并向我们保证您所有的最终用户同意我们收集和使用其信息，并且同意遵守本隐私权政策的全部规定，您已经遵守并将持续遵守适用的法律、法规和监管要求，包括但不限于制定和公布有关个人信息保护和隐私保护的相关政策。
        </p>
        <p>
          您在此进一步保证，您的最终用户不会因为我们按照本隐私权政策的规定收集、使用、披露对其的信息而对我们产生任何形式的诉求、诉请、投诉等。如您的最终用户因本隐私权政策项下对其信息的收集、使用、披露而致使我们遭受任何形式的诉请、诉求以及投诉等，您将负责全面给予解决；如导致我们发生任何形式的损失，您将负责给予我们全额赔偿。
        </p>
        <br />
        <h2>四、信息的更新与保存</h2>
        <br />
        <p>
          四、信息的更新与保存
          我们将努力使您能够审阅、更新或更正您存储在TutorData的信息。如需审阅、更新或更正，请访问我们的网站并登录您的账户。为了辨别您身份、核实并处理您的请求，我们将需要您提交充分有效的身份信息。
        </p>
        <p>
          请注意：尽管有上述规定，为解决争议、执行用户协议、遵守与安全运营相关的技术要求，在法律允许的范围内，我们仍可能保留某些数据到合理的时间。
        </p>
        <br />
        <h2>五、我们采取的数据保密措施</h2>
        <br />
        <p>
          在互联网上没有任何传输方法，或电子存储方法是百分之百安全的，尽管我们已经尽到合理努力，但仍有可能被盗、非法拥有或者滥用而给您和您的用户带来人身、财产和声誉等方面损失的危险。您已了解上述风险并自愿承担。
        </p>
        <p>
          我们将采用行业内通行的、合理的标准来保护我们所储存的信息的安全性和保密性。包括但不限于：防火墙和数据备份措施；数据中心的访问权限限制；雇员保密协议和安全管理制度；对移动终端的识别性信息的加密和脱敏处理。
        </p>
        <br />
        <h2>六、我们如何向其他方披露和共享信息</h2>
        <br />
        <p>
          除非本政策已说明的情况外，您的数据不会被以可识别的形式向第三方披露和共享。但以下情况除外：
        </p>
        <b>1、获得您的事先同意；</b><br />
        <b>2、因司法、行政等合法程序的要求；</b><br />
        <b
          >3、在有限的情况下，我们有可能聘请第三方供应商、顾问或其他服务商为我们提供必要的维护、支持和服务，唯该等第三方因履行我们的工作必要，且已与我们签署必要的条款保护数据安全和保密性的；</b
        ><br />
        <b
          >4、在紧急情况下，经合理判断是为了保护我们、我们的代理人、客户、终端用户或其他人的合法权益和安全的；</b
        ><br />
        <b
          >5、我们正在或拟进行企业并购、重组、出售全部或部分股份和/或资产等重大变化，包括但不限于尽职调查过程；</b
        ><br />
        <p>
          但是，我们可能与第三方以不能直接识别您或您用户的形式分享匿名性的或聚合性的信息。
        </p>
        <br />
        <h2>七、我们对未成年人信息保护</h2>
        <br />
        <p>
          我们已经在服务前提中明确，不接受未成年人注册登记成为我们的用户。在知情的情况下，我们不会收集和使用未成年人的数据。
        </p>
        <p>
          如果您的应用是针对未成年人而设计和开发的，请务必确保您的终端用户的注册和使用应用是由未成年人的监护人进行的。
        </p>
        <br />
        <h2>八、政策更新与效力</h2>
        <br />
        <p>
          我们有权根据服务的变化、适用法律和政策的调整以及其他受影响的因素随时调整隐私政策。本隐私政策的任何更新将以标注更新时间的方式公布在本网页上，因此，请随时查看和了解您应遵守的隐私政策。如果您不同意接受本隐私政策，请停止访问和使用我们的服务。
        </p>
        <p>本政策是TutorData服务条款的重要组成部分。</p>
        <br />
        <h2>九、本隐私权政策的修订</h2>
        <br />
        <p>
          我们可能随时会对本隐私权政策进行调整或变更，如发生调整或变更，我们将在TutorData以网站公告的方式予以公布而不再另行单独通知您，该等调整或变更将在公布时即时生效。如您在隐私权政策调整或变更后继续使用TutorData提供的任一服务或访问TutorData相关网站，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
        </p>
        <br />
        <h2>十、联系我们</h2>
        <br />
        <p>如果您对我们的隐私政策有任何疑问、建议或投诉，请联系15101535359。</p>
        <p></p>

        <button class="confirm" @click="close">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { event: {}, closeTop: false, top: 0 };
  },
  created() {},
  components: {},
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.goTop, true);
  },
  watch: {
    // top(e) {
    //   this.event.target.scrollTop = e;
    //   this.goTop(this.event);
    // },
  },
  methods: {
    goTop(e) {
      this.event = e;
      this.top = e.target.scrollTop;
      if (this.closeTop) {
        this.top = 0;
      }
    },
    close() {
      this.closeTop = true;
      this.event.target.scrollTop = 0;
      this.goTop(this.event);
      this.$emit("agreementclose", false);
    },
  },
};
</script>

<style scoped >
.confirm {
  display: block;

  width: 120px;
  height: 40px;
  background: #f28335;
  border-radius: 4px;

  /* width: 60px;
  height: 30px; */
  line-height: 40px;
  border: none;
  margin: 30px auto 10px;

  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.txt {
  background: #fff;
}
.agreement {
  overflow: scroll;
}
.agreement,
.txt {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -340px 0 0 -400px;
  width: 800px;
  height: 684px;
  line-height: 36px;
  padding: 90px 90px 30px;
}
h1,
h2 {
  text-align: center;
}
ul {
  padding: 0 30px;
}
.top {
  position: relative;
  top: -68px;
  right: -55px;
}
.close {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}
</style>
