import Vue from 'vue'
import App from './App.vue'
import Meta from 'vue-meta'
import router from './router'
import store from './store'
import iView from 'view-design';
import VueCookies from 'vue-cookies'
import config from './libs/config/config'
import 'view-design/dist/styles/iview.css';
import './assets/iconfont/iconfont.css';
import './style/main.css';

import "../public/rem"
import preventReClick from '../public/index'
Vue.use(preventReClick);

import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'

Vue.use(VueCookies)
Vue.use(config);
Vue.use(Meta)
Vue.use(iView);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) store.commit('CHANGE_META_INFO', to.meta.metaInfo)
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);//返回页面顶部
})
new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [
        {
          name: 'keywords',
          content: this.$store.state.metaInfo.keywords
        },
        {
          name: 'description',
          content: this.$store.state.metaInfo.description
        }
      ]
    }
  },
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
