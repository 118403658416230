import axios from 'axios';


let util = {};

util.title = function (title) {
  title = title || 'tutordata-server-analysis';
  window.document.title = title;
};

util.ajaxUrl = /localhost|dev/.test(location.origin) ? "http://devws.tutordata.cn"
  : /uat/.test(location.origin) ? "http://uatws.tutordata.cn"
    : "https://ws.tutordata.cn";
// util.ajaxUrl = '/';     //dev
// util.ajaxUrl = 'http://devws.tutordata.cn/';     //dev
// util.ajaxUrl = 'http://uatws.tutordata.cn/';     //uat
// util.ajaxUrl = 'http://ws.tutordata.cn/';     //线上


util.ajax = axios.create({
  baseURL: util.ajaxUrl,
  timeout: 300000
});

export default util;