
<template @scroll.native="goTop">
  <div>
    <div class="txt">
      <div class="top">
        <img
          class="close"
          @click="close"
          src="../assets/images/close.png"
          alt=""
        />
      </div>
      <div class="agreement">
        <h1>服务协议</h1>
        <br />
        <br />
        <p>
          TutorData服务（以下简称“本服务”）是由本公司提供的“TutorData数据分析平台”的服务。为方便您合理使用本服务，请您在使用本服务前务必审慎阅读、充分理解《TutorData网站服务协议》（以下简称“本协议”）及部分服务项目单项协议的各条款内容，特别是免除或者限制责任的条款，以及注册开通或使用某项服务的单独协议。
          您若开始使用TutorData提供的任何一项服务即视为您已阅读并充分理解本网站服务条款，且完全同意上述全部协议的约束。如果您未满18周岁，请在法定监护人的陪同下阅读本协议，注意理解各条款内容。
        </p>

        <br />
        <h2>一、协议的范围</h2>
        <br />
        <p>
          一、协议的范围
          本协议的主体是TutorData网站或移动应用的用户（本网站相关服务的使用人）与TutorData网站（www.tutordata.cn和关联网站，以下称本网站）及其相关服务可能存在的运营关联单位。
        </p>
        <br />
        <h2>二、协议的内容</h2>
        <br />

        <h3>2.1 本协议项下的服务（以下简称“本服务”）</h3>
        <p>是由本公司提供的互联网应用的交付和运维平台，及其周边服务。</p>
        <h3>2.2 使用 TutorData 的服务方式如下:</h3>
        <br />
        <ul>
          <li>
            2.2.1
            除非您明确注明，否则视为您同意本服务仅为您个人，或您所服务的企业、机构所使用。
          </li>
          <li>2.2.2 您应当通过本网站提供或认可的方式使用本服务。</li>
          <li>
            2.2.3
            您不得使用未经本公司授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
          </li>
          <li>
            <p>2.2.4 收费服务</p>
            <ul>
              <li>2.2.5 第三方提供的产品或服务</li>
            </ul>
          </li>
          <li>
            <p>2.2.5 第三方提供的产品或服务</p>
            <ul>
              <li>
                您在本网站上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。本网站和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <h2>三、用户使用的承诺与保证</h2>
        <p>为保障您使用本服务时的合法权益，您同意做出如下承诺与保证：</p>
        <h3>3.1 若您在TutorData注册并取得TutorData帐号则证明您同意并保证：</h3>

        <ul>
          <li>
            <p>3.1.1 注册信息的真实性、准确性、完整性保证：</p>
            <ul>
              <li>
                您所填写的用户信息是真实、准确、完整、及时的，且保证TutorData可以通过您所填写的联系方式与您取得联系，否则您不能享受TutorData向您提供相关服务或享受的服务受到部分限制。
              </li>
              <li>
                TutorData有权对您提供的资料进行合理审查，若您提供的资料、信息或证件包含有不正确、不真实的信息，TutorData有拒绝为您提供服务或随时结束已向您提供的服务的权利。
              </li>
            </ul>
          </li>
          <li>
            <p>3.1.2 帐号使用及注册的排他性保证：</p>
            <ul>
              <li>
                您应对您注册在TutorData的帐号负责，只有您本人可以使用您的在TutorData的帐号，该帐号不可转让、不可赠与、不可继承。除非您向TutorData提供代理人和被代理人的详细资料和书面授权，未提供上述资料和文件的，TutorData将视注册者为会员。
              </li>
            </ul>
          </li>
          <li>
            <p>3.1.3 帐号修改的限制性说明：</p>
            <ul>
              <li>
                一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。因此，请您务必注意TutorData对注册信息作出的相关提示。
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>3.2 帐号保密性保证：</h3>
        <br />
        <p>
          您将对使用该账户及密码进行的一切操作及言论负完全的责任，且您已同意：
        </p>
        <br />
        <ul>
          <li>
            TutorData通过您的用户名和密码识别您的指示，TutorData郑重提醒您请妥善保管您的用户名和密码，对于因密码泄露所致的损失，由您自行承担。
          </li>
          <li>
            如您发现有他人冒用或盗用您的账户及密码或任何其他未经合法授权之情形时，应立即以有效方式通知TutorData，要求TutorData暂停相关服务。同时，您理解TutorData对您的请求采取行动需要合理期限，在此之前，TutorData对已执行的指令及所导致的您的损失不承担任何责任。
          </li>
        </ul>
        <br />
        <h3>3.3 用户行为合法性保证</h3>
        <br />
        <ul>
          <li>
            <p>
              3.3.1
              您已保证，在使用TutorData网站提供服务遵守法律法规，不得利用TutorData从事任何违法违规行为，包括但不限于：
            </p>
            <ul>
              <li>
                发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
              </li>
              <li>
                发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
              </li>
              <li>恶意虚构事实、隐瞒真相以误导、欺骗他人；</li>
              <li>发布、传送、传播广告信息及垃圾信息；</li>
              <li>其他法律法规禁止的行为。</li>
            </ul>
          </li>
          <li>
            3.3.2
            您应当保证，所有在本网站上传的文件、图片等均具有合法来源，否则由此引发的所有争议，TutorData将不承担责任。且若因此导致TutorData陷入诉讼或其他纠纷，您应当赔偿TutorData相应的损失。
          </li>
          <li>
            3.3.3
            若您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求TutorData给予协助。造成损害的，您应依法予以赔偿，TutorData不承担任何责任。
          </li>
          <li>
            3.3.4
            若TutorData发现或收到他人举报您发布的信息违反本条约定，本公司有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，TutorData有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账户使用，追究法律责任等措施。
          </li>
          <li>
            3.3.5
            您违反本条约定，导致任何第三方损害的，您应当独立承担责任；TutorData因此遭受损失的，您也应当一并赔偿。
          </li>
          <li>
            3.3.6
            若因您的行为导致TutorData卷入政治争议中，给TutorData造成的损失由您承担。
          </li>
        </ul>
        <br />
        <h3>3.4 服务规则的使用</h3>
        <br />
        <ul>
          <li>
            3.4.1
            您了解并同意TutorData单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示为准。若您不同意修改后的服务规则，可及时终止使用TutorData提供的服务，否则将视为您同意。
            因此，TutorData郑重提醒您时刻注意本网站对您注册时使用的联系方式发送的任何邮件、信息等，否则因信息更新不及时给您造成的损失，道客不承担任何责任。
          </li>
          <li>
            3.4.2
            当您在违反本协议条款的情况下，本公司有权拒绝继续为您提供任何服务。
          </li>
          <li>
            3.4.3
            您将认可TutorData中出现部分广告信息。您对TutorData服务中出现的广告信息，应自行审慎判断其真实性和可靠性，除法律明确规定外，您应自行对依该广告信息进行的交易负责。
          </li>
          <li>
            3.4.4
            在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意TutorData有关防范诈骗犯罪的提示。
          </li>
        </ul>
        <br />
        <h2>四、本公司对用户的承诺</h2>
        <br />
        <b>信息保密性保证</b>
        <p>
          保护用户个人信息是TutorData的一项基本原则。TutorData将按照本协议的规定收集、使用、储存和分享您的个人信息，并将运用现有的各种安全技术和程序建立相对完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
        </p>
        <p>TutorData不会将您的个人信息转移或披露给任何非关联的第三方，除非：</p>

        <ul>
          <li>相关法律法规或法院、政府机关要求；</li>
          <li>为完成合并、分立、收购或资产转让而转移；</li>
          <li>为提供您要求的服务所必需。</li>
        </ul>
        <br />
        <h2>五、TutorData责任的述明</h2>
        <br />
        <ul>
          <li>5.1 不可抗力的免责述明</li>
        </ul>

        <p>
          您充分理解并同意，TutorData的服务是按照现有技术和条件所能达到的现状提供的。
          TutorData会尽最大努力向您提供服务，确保服务的连贯性和安全性；但TutorData不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。当您遭遇上述风险，TutorData将不会对您的损失承担责任。
        </p>

        <ul>
          <li>
            5.2
            TutorData依据本协议约定获得处理违法违规内容的权利，该权利不构成TutorData的义务或承诺，TutorData不能保证及时发现违法行为或进行相应处理。
          </li>
          <li>
            5.3
            由于注册信息均由用户自行提供，TutorData将不会对其真实性、准确性、完整性作出任何保证。
          </li>
        </ul>
        <br />
        <h2>六、知识产权声明</h2>
        <br />
        <ul>
          <li>
            6.1
            TutorData在本网站中提供的内容（包括但不限于logo、网页、文字、图片、音频、视频、图表等）的知识产权全部归TutorData所有，用户在使用TutorData提供的服务中所产生的内容的知识产权归用户或相关权利人所有。
          </li>
          <li>
            6.2
            除另有特别声明外，TutorData提供本服务时所依托软件的著作权、专利权及其他知识产权均归TutorData所有。
          </li>
          <li>
            6.3
            上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经TutorData、用户或相关权利人书面许可，任何人不得以任何形式进行披露、使用、允许他人使用或创
            造相关衍生作品。
          </li>
        </ul>
        <br />
        <h2>七、服务的变更、中断、终止</h2>
        <br />
        <ul>
          <li>
            7.1
            TutorData可能会对服务内容进行变更，也可能会中断、中止或终止服务。
          </li>
          <li>
            7.2
            您理解并同意，TutorData有权自主决定经营策略。在TutorData发生合并、分立、收购、资产转让时，本公司可向第三方转让本服务下相关资产；TutorData也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以本公司通知的为准。
          </li>
          <li>
            7.3
            如发生下列任何一种情形，TutorData有权不经通知而中断或终止向您提供的服务：
            <ul>
              <li>
                7.3.1
                根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
              </li>
              <li>7.3.2 您违反相关法律法规或本协议的约定；</li>
              <li>7.3.3 按照法律规定或主管部门的要求；</li>
              <li>7.3.4 出于安全的原因或其他必要的情形。</li>
            </ul>
          </li>
          <li>
            7.4
            您有责任自行备份存储在本服务中的数据。如果您的服务被终止，TutorData可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后，TutorData没有义务向您返还数据。
          </li>
        </ul>
        <br />
        <h2>八、其他</h2>
        <br />
        <p>
          如果您对本协议或本服务有意见或建议，可拨打15101535359与TutorData客户服务部门联系，我们会给予您必要的帮助。
        </p>
        <button class="confirm" @click="close">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { event: {}, closeTop: false, top: 0 };
  },
  created() {},
  components: {},
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.goTop, true);
  },
  methods: {
    goTop(e) {
      this.event = e;
      this.top = e.target.scrollTop;
      if (this.closeTop) {
        this.top = 0;
      }
    },
    close() {
      this.closeTop = true;
      this.event.target.scrollTop = 0;
      this.goTop(this.event);

      this.$emit("privacyclose", false);
    },
  },
};
</script>

<style scoped >
.confirm {
  display: block;

  width: 120px;
  height: 40px;
  background: #f28335;
  border-radius: 4px;

  line-height: 40px;
  border: none;
  margin: 30px auto 10px;

  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.txt {
  background: #fff;
}
.agreement {
  overflow: scroll;
}
.agreement,
.txt {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -340px 0 0 -400px;
  width: 800px;
  height: 684px;
  line-height: 36px;
  padding: 90px 90px 30px;
}
h1,
h2 {
  text-align: center;
}
ul {
  padding: 0 30px;
}
.top {
  position: relative;
  top: -68px;
  right: -55px;
}
.close {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}
</style>
