<template>
  <div>
    <div class="mask"></div>
    <div :class="isShow ? 'login' : 'loginTo'">
      <img
        class="close"
        @click="close"
        src="../assets/images/close.png"
        alt=""
      />

      <div class="content">
        <h2>{{ logTitle }}</h2>
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
          <FormItem prop="name">
            <div class="Register">
              <div class="phone">
                <Input
                  class="phoneInp"
                  style="height: 100%"
                  placeholder="手机号码"
                  v-model="formValidate.name"
                >
                  <span slot="prepend"> 中国大陆 (+86) </span></Input
                >
              </div>
            </div>
          </FormItem>
        </Form>
        <Form
          ref="formValidateMeil"
          :model="formValidate"
          :rules="ruleValidateMail"
        >
          <!-- -->
          <FormItem prop="mail">
            <Input
              class="codeInp"
              placeholder="验证码"
              v-model="formValidate.mail"
            ></Input>
            <button
              type="button"
              v-if="!isCode"
              :class="phone ? 'getCode' : 'notGetCode'"
              @click="getCode"
            >
              获取验证码
            </button>
            <button
              type="button"
              v-else
              class="getCode"
              style="color: #999; border: none"
              disabled="disabled"
            >
              {{ CountdownNum }}秒后重试
            </button>
          </FormItem>
        </Form>
        <!-- ///////////// -->
        <Form
          v-if="!isShow"
          ref="formValidateInfo"
          :model="formValidate"
          :rules="ruleValidateInfo"
        >
          <FormItem prop="companyVal">
            <Input
              v-model="formValidate.companyVal"
              class="inpWH"
              placeholder="公司或组织"
            ></Input>
          </FormItem>
          <FormItem prop="emailVal">
            <Input
              class="inpWH"
              placeholder="公司邮箱"
              v-model="formValidate.emailVal"
            ></Input>
          </FormItem>
          <FormItem prop="tradeVal">
            <Select
              size="large"
              v-model="formValidate.tradeVal"
              class="inpWH"
              placeholder="行业"
            >
              <Option disabled selected value>请选择行业</Option>
              <Option v-for="(value, key) in trade" :key="key" :value="key">
                {{ value }}
              </Option>
              <Option value="OTHER">其他</Option>
            </Select>
          </FormItem>
          <FormItem prop="branchVal">
            <Select
              v-model="formValidate.branchVal"
              class="inpWH"
              size="large"
              placeholder="部门"
            >
              <Option disabled selected value>请选择部门</Option>
              <Option v-for="(value, key) in branch" :key="key" :value="key">
                {{ value }}
              </Option>
              <Option value="OTHER">其他</Option>
            </Select>
          </FormItem>

          <p class="txt">
            登录即代表您已同意 <a @click="privacyIn">《服务协议》</a>和<a
              @click="agreenIn"
              >《隐私政策》</a
            >
          </p>
        </Form>

        <Button class="loginBtn" @click="login()"> {{ logTitle }}</Button>
      </div>
      <Loading class="Loading" v-show="loading"></Loading>
    </div>
  </div>
</template>

<script>
import api from "../libs/api";
import Loading from "./loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      logTitle: "登录",
      isCode: false, //倒计时
      isShow: true, //是否注册  默认已注册  已注册 false,未注册 true
      loading: false, //loading
      register: false, //注册，登陆传false,注册传true
      phone: false,
      CountdownNum: 60,
      timer: "", //定时器
      trade: {}, //行业
      branch: {}, //部门

      formValidate: {
        name: "",
        mail: "",
        tradeVal: "", //行业
        companyVal: "", //公司或组织
        emailVal: "", //邮箱
        branchVal: "", //部门
      },

      ruleValidate: {
        name: [{ validator: this.validatorName, trigger: "blur" }],
      },
      ruleValidateMail: {
        mail: [{ validator: this.validatoremail, trigger: "blur" }],
      },

      ruleValidateInfo: {
        tradeVal: [
          {
            required: true,
            message: "请选择行业",
            trigger: "blur",
          },
        ],
        companyVal: [
          {
            required: true,
            message: "请输入公司或组织",
            trigger: "blur",
          },
        ],
        emailVal: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          { type: "email", message: "邮箱格式错误", trigger: "blur" },
        ],
        branchVal: [
          {
            required: true,
            message: "请选择部门",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  computed: {},
  watch: {
    isShow(v) {
      this.isShow = v;
      this.title();
    },
  },
  mounted() {},
  methods: {
    // 验证手机号
    validatorName(rule, value, callback) {
      let reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/; //手机号

      if (value == "") {
        this.phone = false;
        callback(new Error("手机号不能为空"));
      } else if (!reg.test(value)) {
        this.phone = false;
        callback(new Error("手机号格式不正确"));
      } else {
        this.phone = true;
        callback();
      }
    },

    validatoremail(rule, value, callback) {
      if (value.length != 4) {
        callback(new Error("请输入四位验证码"));
      } else {
        callback();
      }
    },

    // 获取验证码
    getCode() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          api.getCode(this.formValidate.name).then((res) => {
            if (res.data.status == 1) {
              this.isCode = true;
              this.Countdown(); //倒计时
            } else if (res.data.status == -1001002) {
              alert("短信发送太频繁");
            } else if (res.data.status == -1001003) {
              alert("发送短信失败");
            } else {
              alert("获取验证码失败");
            }
          });
        }
      });
    },
    //倒计时
    Countdown() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.CountdownNum--;
        if (this.CountdownNum < 0) {
          clearInterval(this.timer);
          this.CountdownNum = 60;
          this.isCode = false;
        }
      }, 1000);
    },
    //关闭登录
    close() {
      this.formValidate = {
        name: "",
        mail: "",
        tradeVal: "", //行业
        companyVal: "", //公司或组织
        emailVal: "", //邮箱
        branchVal: "", //部门
      };
      this.phone = false;
      this.isShow = true;
      this.register = false; //登陆传false,注册传true
      this.isCode = false;
      clearInterval(this.timer);
      this.$emit("close", false);
    },
    title() {
      if (this.isShow) {
        this.logTitle = "登录";
      } else {
        this.logTitle = "注册";
      }
    },
    // 登录
    login() {
      let data = {
        phone: this.formValidate.name,
        verifyCode: this.formValidate.mail,
        register: this.register,
        company: this.formValidate.companyVal, //公司
        industry: this.formValidate.tradeVal, //行业
        department: this.formValidate.branchVal, //部门
        enterpriseEmail: this.formValidate.emailVal, //企业邮箱
      };
      if (this.isShow) {
        this.$refs.formValidate.validate((valids) => {
          this.$refs.formValidateMeil.validate((valid) => {
            if (valids && valid) {
              this.loginDemo(data);
            }
          });
        });
      } else {
        this.$refs.formValidateInfo.validate((valid) => {
          if (valid) {
            this.loginDemo(data);
          }
        });
      }
    },

    loginDemo(data) {
      this.loading = true;
      // 登录
      api.login(data).then((res) => {
        // 未注册
        if (res.data.status == -1001006) {
          // 验证码错误
          this.$Message.error("验证码错误");
        } else if (res.data.status == -1001005) {
          //  用户未注册
          // 获取行业信息

          api.getBaseInfo().then((res) => {
            let tradeObj = res.data.fields;
            this.trade = tradeObj.industry;
            this.branch = tradeObj.department;

            delete this.trade.OTHRE;
            delete this.branch.OTHER;
          });
          this.isShow = false; //已注册 false,未注册 true
          this.register = true; //登陆传false,注册传true

          // this.background("warning");
        } else if (res.data.status == 1) {
          // alert("已登录");
          // 登录成功，更改全局变量
          let userInfo = res.data.fields;
          this.$cookies.set("userInfo", userInfo);
          this.$cookies.set("userP", this.formValidate.name);
          this.loginInfo.isLogin = true; //已登录
          this.$emit("login", this.loginInfo.isLogin); //修改导航栏

          this.isShow = true; //已注册 false,未注册 true
          this.register = false; //登陆传false,注册传true
          // this.background("success");
          this.close(); //关闭登录弹框
          this.demo(); //跳转demo
        }
        this.loading = false;
      });
    },
    demo() {
      // 已登录
      api.loginTutor().then((res) => {
        if (res.data.status == 1) {
          // =-1001001
          let users = {
            token: res.data.fields.token,
            userId: res.data.fields.userId,
            userName: this.$cookies.get("userP"),
          };
          let userInfo =
            "?token=" +
            users.token +
            "&userId=" +
            users.userId +
            "&userName=" +
            users.userName;
          let url = /localhost|dev/.test(location.origin)
            ? "https://dev.tutordata.cn/"
            : /uat/.test(location.origin)
            ? "http://uat.tutordata.cn/"
            : "https://demo.tutordata.cn/";
          window.open(url + userInfo); //线上
        }
      });
    },
    background(type) {
      if (type == "warning") {
        this.$Message[type]({
          background: true,
          content: "请注册！",
        });
      } else {
        this.$Message[type]({
          background: true,
          content: "已登录！",
        });
      }
    },
    privacyIn() {
      this.$emit("privacy", true);
    },
    agreenIn() {
      this.$emit("agreement", true);
    },
  },
};
</script>

<style scoped >
.Loading {
  position: absolute;
  top: 42%;
  left: 41%;
}
.Register {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  align-items: flex-start;
}
.inpWH {
  width: 380px;
  height: 44px;
  text-align: left;
  background: #ffffff;
  /* border: 1px solid #dddddd;
  padding: 0 10px; */
  outline: none;
}
.Register p {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 5px;
  color: #ff0000;
  line-height: 17px;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(212, 212, 214, 0.5);
}
.login,
.loginTo {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  background: #fff;
  display: flex;
  align-content: center;
  margin-left: -220px;
  width: 480px;
  padding-left: 46px;
  margin-top: -242px;
}
.login {
  margin-top: -340px;
}
.loginTo {
  margin-top: -325px;
  overflow: hidden;
  animation: example 1s ease-in-out forwards;
}
@keyframes example {
  from {
    height: 383px;
  }
  to {
    height: 640px;
  }
}
.close {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.content {
  margin-top: 42px;
  /* padding-left: 2rem; */
}
.content h2 {
  font-size: 30px;
}
.phone {
  display: flex;
  align-items: center;
}
.phone,
.code {
  width: 383px;
  height: 44px;
  background: #ffffff;
  /* border: 1px solid #dddddd; */
  line-height: 44px;
  font-size: 14px;
}
.code {
  border: none;
  display: flex;
  justify-content: space-between;
}
.phone span:nth-child(1) {
  font-size: 14px;
  color: #333333;
  margin-left: 10px;
}
.phone span:nth-child(2) {
  color: #dddddd;
  margin: 0 20px;
}
.phoneInp {
  font-size: 14px;
  border: none;
  padding-left: 10px;
  /* height: 30px;
  width: 180px; */
  width: 100%;
  height: 100%;
  outline: none;
}

.codeInp::-webkit-input-placeholder {
  color: #999;
  font-size: 14px;
}
.codeInp:-moz-placeholder {
  color: #999;
  font-size: 14px;
}
.codeInp:-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}
.codeInp {
  outline: none;
  width: 234px;
  height: 44px;
  /* padding-left: 10px; */
  background: #ffffff;
  /* border: 1px solid #dddddd; */
}
.getCode {
  width: 130px;
  height: 44px;
  background: #fef5ef;
  border-radius: 1px;
  margin-left: 10px;
  color: #f28335;
  border: 1px solid #f28335;
}
.notGetCode {
  width: 130px;
  height: 44px;
  background: #fef5ef;
  border-radius: 1px;
  margin-left: 10px;
  color: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed;
}
.txt {
  font-size: 14px;
  margin-left: -72.5px;
  margin-top: 21px;
}
.loginBtn {
  width: 392px;
  height: 62px;
  border: none;
  background: #f28335;
  box-shadow: 0px 5px 5px 0px rgb(178 95 36 / 24%);
  border-radius: 4px;
  font-size: 20px;
  margin: 20px 0 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
}
.loginBtn:hover {
  background: #f28335;
  color: #ffffff;
}
a {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f28335;
}
</style>
