import util from "./util";
import Cookies from 'js-cookie';



let api = {};

// 行业数据
api.getBaseInfo = () => {
  return util.ajax({
    url: `/api/user/login/base/info`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    }
  });
};

// 获取验证码
api.getCode = (data) => {
  return util.ajax({
    url: `/api/user/login/verifyCode?phone=` + data,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    }
  });
};

//登录
api.login = (data) => {
  return util.ajax({
    url: `/api/user/login`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data
  });
};
//登录tutor

api.loginTutor = () => {
  return util.ajax({
    url: `/api/user/login/tutor`,
    headers: {
      "Content-Type": "application/json",
      "userId": JSON.parse(Cookies.get('userInfo')).userId,
      "token": JSON.parse(Cookies.get('userInfo')).token
    },
    method: "get",

  });
};
// 退出登录
api.Logout = () => {
  return util.ajax({
    url: `/api/user/logout`,
    headers: {
      "Content-Type": "application/json",
      "userId": JSON.parse(Cookies.get('userInfo')).userId,
      "token": JSON.parse(Cookies.get('userInfo')).token
    },
    method: "post",

  });
}

export default api;
