import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      metaInfo: {
        title: "图拓数据 - 聚焦用户行为的大数据分析平台",
        keywords: "用户行为分析,数据采集,数据分析,渠道分析,用户分群,精细化运营,数据驱动",
        description: "图拓数据(官网)，提供大数据分析与可视化解决方案，赋能企业数据驱动营销能力，助力高效决策，覆盖多行业场景，满足个性化及复杂分析需求"
      }
    },
    component: () => import(/* webpackChunkName: 'home' */ '@v/Home.vue')
  },
  {
    path: '/data-collection-plan',
    name: 'DataCollectionPlan',
    meta: {
      metaInfo: {
        title: "数据采集方案-可视化分析平台 | TutorData",
        keywords: "数据采集方案,tutor,analysis",
        description: "数据采集方案-可视化分析平台 | TutorData"
      }
    },
    component: () => import(/* webpackChunkName: 'datacollectionplan' */ '@v/DataCollectionPlan.vue')
  },
  {
    path: '/paas-platform',
    name: 'paas-platform',
    meta: {
      metaInfo: {
        title: "PaaS平台-可视化分析平台 | TutorData",
        keywords: "PaaS平台,tutor,analysis",
        description: "PaaS平台-可视化分析平台 | TutorData"
      }
    },
    component: () => import(/* webpackChunkName: 'paasplatform' */ '@v/PaasPlatform.vue')
  },
  {
    path: '/analysis-platform',
    name: 'analysis-platform',
    meta: {
      metaInfo: {
        title: "免费数据分析平台-可视化分析平台 | TutorData",
        keywords: "免费数据分析平台,tutor,analysis",
        description: "免费数据分析平台-可视化分析平台 | TutorData"
      }
    },
    component: () => import(/* webpackChunkName: 'analysisplatform' */ '@v/AnalysisPlatform.vue')
  },
  {
    path: '/operation',
    name: 'operation',
    meta: {
      metaInfo: {
        title: "智能运营-可视化分析平台 | TutorData",
        keywords: "智能运营,tutor,analysis",
        description: "智能运营-可视化分析平台 | TutorData"
      }
    },
    component: () => import(/* webpackChunkName: 'analysisplatform' */ '@v/operation.vue')
  },
  {
    path: '/price',
    name: 'price',
    meta: {
      metaInfo: {
        title: "价格-可视化分析平台 | TutorData",
        keywords: "价格,tutor,analysis",
        description: "价格-可视化分析平台 | TutorData"
      }
    },
    component: () => import(/* webpackChunkName: 'analysisplatform' */ '@v/Price.vue')
  },
  {
    path: '/trackTest-Web',
    name: 'trackTest-Web',
    meta: {
      metaInfo: {
        title: "价格-可视化分析平台 | TutorData",
        keywords: "价格,tutor,analysis",
        description: "价格-可视化分析平台 | TutorData"
      }
    },
    component: () => import('@v/act/trackTest-Web.vue')
  },
  {
    path: '/tutorDeeplinkTest',
    name: 'tutorDeeplinkTest',
    meta: {
      metaInfo: {
        title: "价格-可视化分析平台 | TutorData",
        keywords: "价格,tutor,analysis",
        description: "价格-可视化分析平台 | TutorData"
      }
    },
    component: () => import('@v/act/tutorDeeplinkTest.vue')
  }

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

})

export default router
