import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  metaInfo: {
    title: "数据分析-可视化分析平台 | TutorData",
    keywords: "数据分析,tutor,analysis",
    description: "数据分析-可视化分析平台 | TutorData"
  }
}

const mutations = {
  CHANGE_META_INFO(state, metaInfo) {
    state.metaInfo = metaInfo
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {}
})
