<template>
  <div id="app">
    <header class="nav">
      <nav class="nav-container con-wrap">
        <router-link to="/" exact>
          <img class="logo" src="./assets/images/logo_icon.png" alt="logo" />
        </router-link>
        <div v-if="width" class="top-nav">
          <router-link v-for="route in routeList" :data-agl-cvt="route.path == '/price' ? '1' : ''" :to="route.path"
            :key="route.path">
            {{ route.title }}
          </router-link>
        </div>

        <div v-else class="semall">
          <Dropdown style="margin-left: 20px">
            <a href="javascript:void(0)">
              产品 <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <router-link v-for="route in routes" :key="route.path" :to="route.path">
                {{ route.title }}
              </router-link>
            </DropdownMenu>
          </Dropdown>
          <div>
            <router-link style="margin: 0 1rem 0 0" to="/price" data-agl-cvt="1">价格</router-link>
          </div>
        </div>
        <Poptip confirm :disabled="!isLogin" title="是否退出登录？" placement="bottom-end" @on-ok="delReg">
          <button class="btn" @click="register" data-agl-cvt="5"
            :onclick="'return gtag_report_conversion(' + openUrl + ')'">
            <i v-show="isLogin" class="iconfont icon-denglu" /> {{ Register }}
          </button>
        </Poptip>

        <!-- <span class="phone">电话：151 0153 5359</span> -->
      </nav>
    </header>

    <!--路由视图-->
    <router-view @priceRegister="priceRegister" @register="register" :isLogin="isLogin" />

    <footer class="page-footer">
      <div class="footer-container con-wrap">
        <div class="footer-list footer-left">
          <router-link to="/" exact>
            <h1 style="color: #fff">
              <img src="./assets/images/logo_text.png" alt="" />
            </h1>
          </router-link>

          <p>©北京初联科技有限公司</p>
          <p>电话: 010-62282362</p>
          <p>地址: 北京市海淀区长远天地大厦B2座3A10</p>
        </div>

        <div class="footer-list">
          <h4>产品</h4>
          <div class="box">
            <div class="box_left">
              <router-link to="/data-collection-plan">数据采集方案</router-link>
              <router-link to="/paas-platform">PaaS平台</router-link>
              <router-link to="/analysis-platform">数据分析平台</router-link>
            </div>
            <div class="box_right">
              <router-link to="/operation">智能运营</router-link>
              <router-link to="/Price">价格</router-link>
            </div>
          </div>
        </div>

        <div class="footer-list">
          <h4>联系我们</h4>
          <p>Tel: 151 0153 5359</p>
        </div>
      </div>
      <div class="recordBox con-wrap">
        <div class="record">
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index
">
            <p>备案号：京ICP备14014002号-34</p>
          </a>
        </div>
        <div class="record">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033245">
            <img class="footerImg" src="./assets/images/record.png" style="float: left" />
            <p>京公网安备 11010802033245号</p>
          </a>
        </div>
      </div>
    </footer>
    <Login v-show="maskFlag" @privacy="privacy" @agreement="agreement" @close="close" @login="login"></Login>
    <Agreement v-show="agreementFlag" @agreementclose="agreementclose"></Agreement>
    <Privacy v-show="privacyFlag" @privacyclose="privacyclose"></Privacy>
  </div>
</template>

<script>
import api from "./libs/api";
import Cookies from "js-cookie";
import Login from "./components/login.vue";
import Agreement from "./components/agreement.vue";
import Privacy from "./components/privacy.vue";
import "../public/sdk/tutordata.min.js"
export default {
  data() {
    return {
      openUrl: /localhost|dev/.test(location.origin)
        ? "'https://dev.tutordata.cn/'"
        : /uat/.test(location.origin)
          ? "'http://uat.tutordata.cn/'"
          : "'https://tutordata.cn/'",
      Register: "注册/登录",
      isLogin: false,
      maskFlag: false,
      clientWidth: "",
      width: true,
      agreementFlag: false,
      privacyFlag: false,
      routeList: [
        { path: "/data-collection-plan", title: "数据采集方案" },
        { path: "/paas-platform", title: "PaaS平台" },
        { path: "/analysis-platform", title: "数据分析平台" },
        { path: "/operation", title: "智能运营" },
        // { path: "/help", title: "帮助中心" },
        { path: "/price", title: "价格" },
      ],
      routes: [
        { path: "/data-collection-plan", title: "数据采集方案" },
        { path: "/paas-platform", title: "PaaS平台" },
        { path: "/analysis-platform", title: "数据分析平台" },
        { path: "/operation", title: "智能运营" },
      ],
    };
  },
  components: {
    Login,
    Privacy,
    Agreement,
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.clientWidth = document.documentElement.clientWidth;

        this.changeWidth();
      })();
    };
    this.isLogin = this.loginInfo.isLogin;
    this.isregister();
    var tutor = window["tutorDataAnalytic201505"];
    tutor.init({
      server_url: "https://track.tutordata.cn/ta?project=tutordata&token=8H3ldIUUm5btHuCz",
      use_client_time: true,
      send_type: "ajax",
      heatmap: {},
      show_log:false
    });

    tutor.quick("autoTrack");
  },
  methods: {
    changeWidth() {
      if (this.clientWidth > 1260) {
        this.width = true;
      } else {
        this.width = false;
      }
    },
    isregister() {
      if (this.isLogin) {
        this.Register = "退出";
      } else {
        this.Register = "注册/登录";
      }
    },
    register() {
      if (!this.isLogin) {
        this.maskFlag = true; //不可删
        // this.a = true;
      }
    },
    // 退出登录

    delReg() {
      api.Logout().then((res) => {
        if (res.data.status == 1 || res.data.status == -1001001) {
          this.loginOut();
        }
      });
    },
    loginOut() {
      Cookies.remove("userInfo");
      Cookies.remove("userP");
      this.loginInfo.isLogin = false;
      // this.Register = "注册/登录";
      this.isLogin = false;
      this.isregister();
      // this.register();
    },
    close(v) {
      this.maskFlag = v;
    },
    login(v) {
      this.Register = "退出";
      this.isLogin = v;
    },
    agreementclose(v) {
      this.agreementFlag = v;
    },
    privacyclose(v) {
      this.privacyFlag = v;
    },
    priceRegister(v) {
      this.maskFlag = v;
    },
    agreement(v) {
      this.agreementFlag = v;
    },
    privacy(v) {
      this.privacyFlag = v;
    },
  },
  watch: {
    isLogin(v) {
      this.isLogin = v;
    },
  },
};
</script>

<style>
html {
  font-size: 1rem;
  background: #f8f9ff;
  user-select: none;
}

/* @media only screen and (max-width: 400px) {
  html {
    font-size: 21.33333333px !important;
  }
}

@media only screen and (max-width: 414px) {
  html {
    font-size: 22.08px !important;
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 25.6px !important;
  }
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: PingFangSC-Regular, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav {
  width: 100%;
  height: 4.4rem;
  background: rgba(248, 249, 255, 1);
}

.btn {
  width: 4.65rem;
  height: 1.7rem;
  font-size: 0.7rem;
  line-height: 1.7rem;
  border-radius: 0.15rem;
  background: #fff;
  border: 1px solid #222832;
}

.con-wrap {
  max-width: 72rem;
  margin: 0 auto;
  padding: 0 8.25rem;
}

.top-nav {
  width: 33rem;
  display: flex;
  justify-content: space-evenly;
}

.section-title {
  height: 2.5rem;
  /* margin: 3.05rem 0 3rem; */
  font-size: 1.8rem;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: rgba(33, 43, 54, 1);
  line-height: 2.2rem;
  text-align: center;
}

.phone {
  font-size: 0.8rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222832;
  line-height: 1.2rem;
}

.nav-container {
  height: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.semall {
  display: flex;
  justify-content: space-between;
  margin-left: 35%;
}

.logo {
  width: 7.2rem;
}

/* .nav-container span */
.nav-container a {
  margin-right: 1rem;
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 40, 50, 1);
  line-height: 1.2rem;
  text-decoration: none;
}

.nav-container a:hover {
  color: rgba(219, 121, 31, 1);
}

a.router-link-exact-active {
  color: rgba(219, 121, 31, 1);
}

/* .nav-container a:first-of-type {
  margin-right: 10%;
} */

.nav-container span {
  margin-right: 0;
}

.page-footer {
  /*height: 13.05rem;*/
  /* margin-top: 3rem; */
  background: rgba(9, 14, 52, 1);
}

.footer-container {
  display: flex;
}

.footer-container>a {
  height: 1.95rem;
  margin: 2.05rem 25% 0 0;
}

.logo-footer {
  width: 8.55rem;
}

.footer-list {
  /* margin-right: 5rem; */
  display: inline-block;
  color: #fff;
}

.footer-list h4 {
  height: 2.55rem;
  margin-top: 1.95rem;
  padding-bottom: 1.3rem;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.footer-list:nth-child(2) {
  margin: 0 10rem;
}

.footer-list a,
.footer-list p {
  height: 1.4rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(99, 115, 129, 1);
  line-height: 1.4rem;
  text-decoration: none;
  display: block;
}

.footer-left {
  margin-top: 2.1rem;
  /* margin-right: 10rem; */
}

.footer-left p {
  margin-bottom: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #637381;
  font-size: 0.81rem;
}

.footer-left a {
  /* margin-bottom: 1.6rem; */
}

.footer-left img {
  width: 6rem;
  height: 1.2rem;
}

.box {
  display: flex;
  justify-content: space-between;
  width: 10rem;
}

.ivu-dropdown-menu a {
  margin: 0.5rem 1rem;
  display: block;
}

.recordBox {
  display: flex;
  width: 40rem;
}

.record {
  width: 30rem;
  margin: 0 auto;
  padding: 2rem 0;
}

.record a {
  display: inline-block;
  text-decoration: none;
  height: 2rem;
  line-height: 2rem;
}

.record a p {
  float: left;
  margin: 0 0 0 0.5rem;

  font-size: 0.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5e6175;
  line-height: 0.85rem;
}

.footerImg {
  width: 1rem;
  height: 1rem;
}
</style>
