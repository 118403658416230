import Cookies from 'js-cookie';
let userInfo = Cookies.get('userInfo');


export default {
    install(Vue, options) {
        if (userInfo) {
            Vue.prototype.loginInfo = {
                isLogin: true,//以登录
            }
        } else {
            Vue.prototype.loginInfo = {
                isLogin: false,//未登录
            }
        }
    }
}